import Splash2 from '../splash-screen/Splash2'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PreviewCheck() {
    const navigate = useNavigate()
    useEffect(() => {
        navigate("/module")

    })
    // return <Splash2 />
    return <></>
}

export default PreviewCheck;