import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setModuleName } from '../../../redux-features/user/userSlice'
import BudgetLogo from '../../common/BudgetCal'

export default function SideBar(props) {
    const dispatch = useDispatch()
    const { nextLink, setNextLink } = props
    const modules = useSelector((state) => state.user.userData?.data)
    useEffect(() => {
        if (!nextLink) {
            let module = modules[0] || []
            dispatch(setModuleName(module?.name))
            setNextLink(module._id)
        } else {
            modules?.map(module => {
                if (module?._id === nextLink) {
                    dispatch(setModuleName(module?.name))
                }
            })
        }

    }, [nextLink, modules])

    return (
        <>
            <div className="sidebar-container">
                <div className="sidebar">
                    {
                        modules?.map((module) => {
                            if (module?.lessons?.length) {
                                return (<>
                                    <Link to={"/lesson?id=" + module?._id} key={module?._id} className={nextLink === module._id ? "bar-item  active" : "bar-item"}
                                        onClick={(e) => {
                                            setNextLink(module._id)
                                        }}
                                    >
                                        <img src={module?.icon?.svg} alt="" />
                                        <p>{module.name}</p>
                                    </Link>
                                </>)
                            }
                        })
                    }
                    <a
                        href={process.env.REACT_APP_BUDG_LINK + "?lang=" + localStorage.getItem("lang")}
                    >
                        <BudgetLogo />
                    </a>
                </div>
            </div>
        </>
    )
}