import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { emptyPoints, markCompleted } from '../../../redux-features/user/userSlice'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react';
import { Box } from '@mui/material'

export default function Feedback(props) {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const session = useSelector(state => state.user.subSkillID)
    const quiz = useSelector(state => state.user.quiz)
    const [active, setActive] = useState(true)
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    useEffect(() => {
        let totalQuizPoints = 0
        if (!!quiz?.length) {
            quiz?.map(que => {
                totalQuizPoints += que?.points;
            })
        }
        // setQtp(totalQuizPoints)
    }, [quiz])

    return (
        <>
            <div className="container-feedback d-flex flex-column justify-content-center align-items-center" style={{ height: "80vh", marginTop: "30px" }}>
                <div className="row m-0 p-0">
                    <h2 className="text-center">{t("feedback.howDoYouDo")}</h2>
                </div>
                <div className="row feedback-content d-flex justify-content-center align-items-center">
                    <div className="col-sm-4">

                        <div className={active ? "svg-border active" : "svg-border"}
                            onClick={() => {
                                setActive(true)
                                setActive1(false)
                                setActive2(false)
                                // setReatingPoints(5)
                            }}
                        >
                            <svg width="60" height="100" viewBox="0 0 60 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM30 56.637C15.2888 56.637 3.363 44.7112 3.363 30C3.363 15.2888 15.2888 3.363 30 3.363C44.7112 3.363 56.637 15.2888 56.637 30C56.637 44.7112 44.7112 56.637 30 56.637ZM17 27C19.2091 27 21 24.5376 21 21.5C21 18.4624 19.2091 16 17 16C14.7909 16 13 18.4624 13 21.5C13 24.5376 14.7909 27 17 27ZM43.3349 38.6637C43.793 38.0451 43.5851 37.1822 42.9301 36.7779C42.2751 36.3737 41.4217 36.5822 40.953 37.1928C39.8159 38.6744 38.3849 39.9122 36.7445 40.8253C34.6969 41.9651 32.3915 42.5611 30.0481 42.5565C27.7047 42.5519 25.4016 41.9468 23.3586 40.799C21.7217 39.8794 20.2956 38.636 19.1643 37.15C18.6981 36.5375 17.8455 36.3256 17.1889 36.7273C16.5323 37.129 16.321 37.9911 16.7767 38.6115C18.1639 40.5002 19.9409 42.0762 21.9932 43.2293C24.4516 44.6104 27.2229 45.3385 30.0426 45.344C32.8624 45.3496 35.6365 44.6324 38.1003 43.2609C40.1571 42.116 41.9403 40.5469 43.3349 38.6637ZM47 21.5C47 24.5376 45.2091 27 43 27C40.7909 27 39 24.5376 39 21.5C39 18.4624 40.7909 16 43 16C45.2091 16 47 18.4624 47 21.5Z" fill="#EFEFEF" />
                                <text
                                    cursor="pointer"
                                    x="45.5%"
                                    y="94%"
                                    fill="#DEDEDE"
                                    font-family="system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
                                    font-style="normal"
                                    font-weight="600"
                                    text-anchor="middle"
                                    font-size="14px"
                                >
                                    {t("itGetIt")}
                                </text>
                            </svg>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className={active1 ? "svg-border active" : "svg-border"}
                            onClick={() => {
                                setActive(false)
                                setActive1(true)
                                setActive2(false)
                                // setReatingPoints(3)
                            }}
                        >
                            <svg width="64" height="100" viewBox="0 0 64 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 39.5C19.1716 39.5 18.5 40.1716 18.5 41C18.5 41.8284 19.1716 42.5 20 42.5H46.5909C47.4193 42.5 48.0909 41.8284 48.0909 41C48.0909 40.1716 47.4193 39.5 46.5909 39.5H20Z" fill="#EFEFEF" />
                                <path d="M23 21.5C23 24.5376 21.2091 27 19 27C16.7909 27 15 24.5376 15 21.5C15 18.4624 16.7909 16 19 16C21.2091 16 23 18.4624 23 21.5Z" fill="#EFEFEF" />
                                <path d="M45 27C47.2091 27 49 24.5376 49 21.5C49 18.4624 47.2091 16 45 16C42.7909 16 41 18.4624 41 21.5C41 24.5376 42.7909 27 45 27Z" fill="#EFEFEF" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M32 60C48.5685 60 62 46.5685 62 30C62 13.4315 48.5685 0 32 0C15.4315 0 2 13.4315 2 30C2 46.5685 15.4315 60 32 60ZM32 56.637C17.2888 56.637 5.363 44.7112 5.363 30C5.363 15.2888 17.2888 3.363 32 3.363C46.7112 3.363 58.637 15.2888 58.637 30C58.637 44.7112 46.7112 56.637 32 56.637Z" fill="#EFEFEF" />
                                <text
                                    cursor="pointer"
                                    x="45.5%"
                                    y="94%"
                                    fill="#DEDEDE"
                                    font-family="system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
                                    font-style="normal"
                                    font-weight="600"
                                    text-anchor="middle"
                                    font-size="14px"
                                >
                                    {t("confused")}
                                </text>
                            </svg>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className={active2 ? "svg-border active" : "svg-border"}
                            onClick={() => {
                                setActive(false)
                                setActive1(false)
                                setActive2(true)
                                // setReatingPoints(2)
                            }}
                        >
                            <svg
                                width="90" height="100" viewBox="0 0 90 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M75 30C75 46.5685 61.5685 60 45 60C28.4315 60 15 46.5685 15 30C15 13.4315 28.4315 0 45 0C61.5685 0 75 13.4315 75 30ZM18.363 30C18.363 44.7112 30.2888 56.637 45 56.637C59.7112 56.637 71.637 44.7112 71.637 30C71.637 15.2888 59.7112 3.363 45 3.363C30.2888 3.363 18.363 15.2888 18.363 30Z" fill="#EFEFEF" />
                                <path d="M57.9301 44.5658C58.5851 44.1615 58.793 43.2986 58.3349 42.68C56.9403 40.7968 55.1571 39.2278 53.1002 38.0828C50.6365 36.7114 47.8624 35.9942 45.0426 35.9997C42.2229 36.0053 39.4516 36.7333 36.9932 38.1145C34.9409 39.2675 33.1639 40.8436 31.7767 42.7322C31.321 43.3526 31.5323 44.2147 32.1889 44.6164C32.8455 45.0181 33.6981 44.8063 34.1643 44.1938C35.2956 42.7077 36.7217 41.4643 38.3586 40.5447C40.4016 39.3969 42.7047 38.7918 45.0481 38.7872C47.3915 38.7826 49.6969 39.3786 51.7445 40.5184C53.3849 41.4316 54.8159 42.6693 55.953 44.1509C56.4217 44.7616 57.2751 44.9701 57.9301 44.5658Z" fill="#EFEFEF" />
                                <ellipse cx="32" cy="21.5" rx="4" ry="5.5" fill="#EFEFEF" />
                                <ellipse cx="58" cy="21.5" rx="4" ry="5.5" fill="#EFEFEF" />
                                {
                                    i18n.language == "sp" ?
                                        <>
                                            <text
                                                cursor="pointer"
                                                x="50%"
                                                y="78%"
                                                fill="#DEDEDE"
                                                font-family="system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
                                                font-style="normal"
                                                font-weight="600"
                                                text-anchor="middle"
                                                font-size="14px"
                                            >
                                                Demasiado
                                                {/* {t("tooComplex")} */}
                                            </text>
                                            <text
                                                cursor="pointer"
                                                x="50%"
                                                y="94%"
                                                fill="#DEDEDE"
                                                font-family="system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
                                                font-style="normal"
                                                font-weight="600"
                                                text-anchor="middle"
                                                font-size="14px"
                                            >
                                                Compleja
                                                {/* {t("tooComplex")} */}
                                            </text>
                                        </>
                                        :
                                        <text
                                            cursor="pointer"
                                            x="50%"
                                            y="94%"
                                            fill="#DEDEDE"
                                            font-family="system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
                                            font-style="normal"
                                            font-weight="600"
                                            text-anchor="middle"
                                            font-size="14px"
                                        >
                                            {t("tooComplex")}
                                        </text>

                                }

                            </svg>
                        </div>
                    </div>
                </div>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                    <Link
                        style={{ width: 'fit-content' }}
                        to="/" className="row"
                        onClick={(e) => {
                            e.preventDefault()
                            dispatch(markCompleted(session))
                            navigate("/quiz-result")
                        }
                        }>
                        <button className="text-light">
                            {t("feedback.btnSubmit")}
                        </button>
                    </Link>
                    <Link to="/"

                        style={{ width: 'fit-content' }}
                        onClick={(e) => {
                            e.preventDefault()
                            dispatch(emptyPoints())
                            props.retry()
                        }}
                        className="row">
                        {quiz.length > 0 ? (<button className="text-light">
                            {t("retryQuiz")}
                        </button>) : ''}
                    </Link>
                </Box>


            </div>
        </>
    )




}
