import React from 'react'
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { setSkillName, setLessonName, setSkill } from '../../redux-features/user/userSlice'
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from '@mui/material/styles';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const Entrepreneur = (props) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useTranslation()
    const user = useSelector((state) => state?.user?.userData?.user);
    const userCompleted = useSelector((state) => state?.user?.userCompleted);
    const { moduleID, } = props;
    const dispatch = useDispatch();
    const settings = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 0,
        infinite: false,
        arrows: false,
        vertical: isMobile ? true : false
    };
    return (
        <>
            {
                props.module?.lessons.length > 0 ?
                    <>
                        <div className='slider_all'>
                            <Slider {...settings} className="slider_setting">
                                {
                                    props.module?.lessons?.map((lesson) => {
                                        return (
                                            <div className="customItem">
                                                <div className="Literacy-card" id='style-3'>
                                                    <div className="box-financial">
                                                        <div style={{ display: "flex", justifyContent: "space-between", }}>
                                                            <h3>{lesson.name}</h3>
                                                        </div>
                                                        <Grid item sx={{ background: userCompleted?.includes(lesson._id) ? "#829d00" : "white", padding: "0.5rem 1rem", borderRadius: "0.5rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography sx={{ color: userCompleted?.includes(lesson._id) ? "white" : "grey" }}>{t("global.completed")}</Typography>
                                                            <Typography sx={{ color: userCompleted?.includes(lesson._id) ? "white" : "black", fontWeight: "700" }}>{lesson.skills.filter(skill => { return (userCompleted?.includes(skill._id)) })?.length}/{lesson?.skills?.length}</Typography>
                                                        </Grid>
                                                    </div>
                                                    <div className="card-body" >
                                                        <div className="scroll_inner" id='style-3'>
                                                            {
                                                                lesson?.skills?.map((skill) => (
                                                                    <>
                                                                        <Link
                                                                            className="skill-next"
                                                                            to={"/skills?moduleID=" + moduleID + "&lessonID=" + lesson._id + "&skillID=" + skill._id}
                                                                            onClick={(e) => {
                                                                                dispatch(setSkillName(skill?.name))
                                                                                dispatch(setLessonName(lesson?.name))
                                                                                dispatch(setSkill(skill))
                                                                            }}
                                                                            style={{ color: userCompleted?.includes(skill._id) ? "#829d00" : "#106EAC" }}
                                                                        >
                                                                            <div className="box-title" style={{ backgroundColor: userCompleted?.includes(skill._id) ? "#E5FEEB" : "rgba(24, 126, 178,0.2)" }}>
                                                                                {skill?.name}
                                                                            </div>
                                                                        </Link>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                            </Slider>

                        </div>
                    </>
                    :
                    <>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <img width="30%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                        </div>
                    </>
            }

        </>
    )
}

export default Entrepreneur






