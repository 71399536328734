import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Button, Typography, Box, Container, useMediaQuery, Link as ALink } from "@mui/material"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ChatBotBody } from "../common/chatbot"
import { useTheme } from '@mui/material/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setModuleName } from '../../redux-features/user/userSlice'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'

const Modules = () => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t, i18n } = useTranslation()
    const userData = useSelector((state) => state?.user?.userData?.data)
    const user = useSelector(state => state.user.userData.user)
    const loading = useSelector(state => state?.user?.loading)
    const [openChatbotModal, setChatbotModal] = useState(false)
    let language = i18n.language === "en" ? "Español" : "English"

    // if (!userData?.length) {
    //     return (<>
    //         {loading && <Loading />}
    //     </>)
    // } 
    return (
        <>
            <Box sx={{ pt: 4, pb: 2 }}>
                <Container maxWidth="lg" sx={{ pb: 3, display: 'flex', justifyContent: 'space-between' }}>
                    <img height={"32px"} width={isMobile ? "170px" : "240px"} src="/assets/img/bb-logo.svg" alt="" />
                    <Button variant='contained'
                        sx={btnStyle}
                        onClick={() => {
                            i18n.changeLanguage(localStorage.getItem("lang") === "en" ? "sp" : "en")
                            queryClient.invalidateQueries("fetching-all-data")
                            localStorage.setItem("lang", localStorage.getItem("lang") === "en" ? "sp" : "en")
                            // window.location.reload()
                        }}
                    >
                        {language}
                    </Button>
                </Container>
                <Box sx={heroStyle}
                >
                    <img
                        style={{
                            height: "100%",
                            position: "absolute",
                            objectFit: 'cover',
                            right: "12%"
                        }}
                        src='./assets/img/personBanner.png' />
                    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', position: 'relative', zIndex: 1 }}>
                        <Typography variant="h3" sx={heroTextStyle}>{t("global.financialEducation")}</Typography>
                        <ALink
                            href="https://www.brooklinebank.com/"
                            sx={btnBackToBBStyle}
                        >
                            <ArrowBackIosIcon sx={{ width: { sm: "24px", xs: "12px" }, height: { sm: "24px", xs: "12px" } }} />
                            {t("global.backToBrooklineBank")}
                        </ALink>
                    </Container>
                </Box>
            </Box >
            <Container maxWidth="lg" >
                <Grid container sx={{ my: 5, gap: 5 }} justifyContent="center">
                    {userData !== undefined &&
                        userData?.map((item, index) => (
                            <Grid item md={userData?.length > 3 ? 4 : 3.5} sm={5.5} xs={12}
                                sx={boxStyle}>
                                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: item.name === "Money" ? "center" : "flex-start" }}>
                                    {
                                        item.name === "Money" ?
                                            <img style={{ width: "250px" }} src={language !== "English" ? "../assets/img/money-logo.png" : "../assets/img/money-logo-spanish.png"} alt="" />
                                            :
                                            item?.icon?.svg &&
                                            <Box className="modulesredesign__moduleName">
                                                <img style={{ width: item?.icon.height ? "fit-content" : "50px", height: item.icon.height ? item.icon.height : "fit-content" }} src={item?.icon?.svg} />
                                            </Box>
                                    }
                                    {
                                        item.name !== "Money" &&
                                        <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "20px", md: "25px" }, gap: "1rem" }} vriant="h4">
                                            {item?.name}
                                        </Typography>
                                    }
                                </Box>
                                <Box>
                                    {
                                        item.name === "Money" ?
                                            <a
                                                href={process.env.REACT_APP_BUDG_LINK + "?lang=" + localStorage.getItem("lang")}
                                                style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer" }}
                                            >
                                                <Button
                                                    sx={{ background: "#829d00", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: "#016001" } }}>
                                                    <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                                                </Button>

                                            </a>
                                            :
                                            item.name === t("askAnything") ?
                                                <Link
                                                    to={"/module"}
                                                    style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer" }}
                                                >
                                                    <Button
                                                        onClick={() => setChatbotModal(true)}
                                                        sx={{ background: "#829d00", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: "#016001" } }}>
                                                        <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                                                    </Button>

                                                </Link>
                                                :
                                                <Link
                                                    onClick={() => { dispatch(setModuleName) }}
                                                    to={!item?.lessons?.length ? "/module" : `/lesson?id=${item?._id}`}
                                                    style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer" }}
                                                >
                                                    <Button disabled={!item?.lessons?.length} sx={{ background: "#829d00", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: "#016001" } }}>
                                                        <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>{t("module.btnStarted")}</Typography>
                                                    </Button>
                                                </Link>
                                    }
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
                <ChatBotBody setChatBotModal={setChatbotModal} data={userData?.map(module => module?.name)} pageName={"Module"} chatBotModal={openChatbotModal} />
            </Container>
        </>
    )

}

export default Modules

const boxStyle = {
    p: 3,
    borderRadius: '2rem',
    background: "#106EAC",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 2
}

const heroStyle = {
    position: "relative",
    background: "#eeedf2", // 'linear-gradient(to bottom, #f0f0f0 0%, #bcbcbc 90%)',
    height: { sm: '300px', xs: "100px" },
    width: '100%',
}

const btnStyle = {
    backgroundColor: "#829d00",
    minHeight: { sm: '48px', xs: "fit-content" },
    padding: '0 clamp(0.625rem, 1.25vw, 1.25rem)',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: { sm: 'bold', xs: 500 },
    color: '#fff',
    fontSize: '1rem',
    border: 0,
    width: 'fit-content',
    '&:hover': {
        backgroundColor: "#106EAC",
        color: 'white'
    }
}

const btnBackToBBStyle = {
    backgroundColor: "transparent",
    minHeight: { sm: '48px', xs: "fit-content" },
    padding: { sm: '10px', xs: "5px" },
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: { sm: 700, xs: 500 },
    fontSize: { sm: '1rem', xs: "10px" },
    width: 'fit-content',
    borderRadius: { sm: "10px", xs: '5px' },
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    border: `1px solid`,
    '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.light,
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
    }
}

const heroTextStyle = {
    maxWidth: { sm: "50%", xs: "70%" },
    color: 'dark',
    fontWeight: 700,
    mb: { sm: 3, xs: 1.5 },
    fontSize: { md: "3rem", sm: "2.3rem", xs: "18px" }
}