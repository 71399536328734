import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { emptyPoints } from '../../../redux-features/user/userSlice'
import SkillsBar from '../skills/SkillsBar';
import { useTranslation } from 'react-i18next'
import RewardsModal from '../details/RewardsModal'
// import Loading from '../../common/Loading'

export default function QuizResult() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [totalPoints, setTotalPoints] = useState(0)
    const lessonID = useSelector(state => state.user.lessonID);
    const quiz = useSelector(state => state.user?.quiz)
    const moduleID = useSelector(state => state.user.moduleID);
    const skillID = useSelector(state => state.user.skillID);
    const skillSections = useSelector(state => state?.user?.skillSections)
    let loading = useSelector(state => state?.user?.loading)
    let navLink = `/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`
    const [open, setOpen] = useState(false)
    let currentModule = useSelector(state => state?.user?.module)
    let currentSkill = useSelector(state => state?.user?.skill)
    const userCompleted = useSelector((state) => state?.user?.userCompleted);

    useEffect(() => {
        if (skillSections?.length > 0) {
            let temp = 0;
            skillSections?.map(ss => {
                temp = temp + ss?.allContent?.points
            })
            setTotalPoints(temp)
        }
    }, [skillSections])

    if (quiz.length == 0) {
        if (!loading) {
            if (userCompleted?.includes(currentModule._id)) {
                navLink = `/lesson?id=${moduleID}`
                navigate(navLink)
            } else {
                if (userCompleted?.includes(currentSkill._id)) {
                    navLink = `/lesson?id=${moduleID}`
                    navigate(navLink)
                } else {
                    navigate(navLink)
                }
            }
        }
    }
    const handleNavigation = () => {

        if (userCompleted?.includes(currentSkill._id)) {
            navLink = `/lesson?id=${moduleID}`
        }
        if (userCompleted?.includes(currentModule._id)) {
            navLink = `/lesson?id=${moduleID}`
        }
        navigate(navLink)
    }

    // if (quiz.length == 0 || loading) {
    //     return <Loading />
    // }
    return (
        <>
            <RewardsModal open={open} setOpen={setOpen} />
            <SkillsBar page="Quiz Result" />
            <div className="skills-details-container quiz-result" style={{ maxHeight: "100vh", overflow: "hidden" }}>
                <div className="row text-center m-0 p-0" style={{ height: '100%' }}>
                    {/* <h6>Module 1</h6> */}
                    <div className="container-feedback" style={{ height: "90vh" }}>
                        <div className="row m-0 p-0">
                            <h2 className="text-center mt-3 mb-md-5">{t("quizResult.results")}</h2>
                        </div>
                        <div className="row feedback-content d-flex justify-content-center m-0 mt-2" >
                            <div className="col-sm-6">
                                <img src="../assets/svg/lesson/quiz-result.svg" alt="" />
                                {
                                    !!quiz?.length &&
                                    <h4 className="mt-2">
                                        {`${skillSections?.length}/${skillSections?.length} ${t("quizResult.completed")}`}
                                    </h4>
                                }

                            </div>
                            {/* <div className="col-sm-4 d-flex justify-content-center">
                                <div className="correct-ans">
                                    <h1>{totalPoints}</h1>
                                    <h4>{t("quizResult.pointsEarned")}</h4>
                                </div>
                            </div> */}
                        </div>
                        <div className="row d-flex justify-content-center mt-md-5 mt-2  ">
                            <h5>{t("quizResult.congratulationsOnCompletion")}</h5>
                        </div>
                        <div className="row d-flex justify-content-center mt-1">
                            <button
                                onClick={() => {
                                    dispatch(emptyPoints())
                                    handleNavigation()
                                }}
                            >{t("quizResult.btnNext")}</button>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )

}
