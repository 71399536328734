import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SideBar from '../details/SideBar'
import SkillOne from '../details/SkillOne'
import { useSelector } from 'react-redux'
import Loading from '../../common/Loading'
import { Grid, Box } from '@mui/material'
import RewardsModal from '../details/RewardsModal'
import TranslateModal from '../details/TranslateModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from '../notes/Notes'
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatBot from '../../common/chatbot.js'
import { useTranslation } from 'react-i18next'
export default function Subskills(props) {
    const { t } = useTranslation()
    const userData = useSelector((state) => state?.user?.userData?.data)
    const modules = useSelector(state => state.user.userData?.data)

    const loading = useSelector(state => state.user?.loading)
    var url = new URL(window.location.href);
    var lessonID = url.searchParams.get("lessonID");
    var moduleID = url.searchParams.get("moduleID");
    var skillID = url.searchParams.get("skillID");
    const [nextLink, setNextLink] = useState(moduleID)
    const [open, setOpen] = useState(false)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [nextSrc, setNextSrc] = useState("../../assets/svg/next.svg");


    const openModal = () => {
        setShowModal(!showModal);
    };

    let nextSkillId = null

    useEffect(() => {

        userData?.map((item) => {
            item?.lessons?.map((lesson) => {
                lesson?.skills?.map((skill, index) => {
                    if (skill?._id == skillID) {
                        if (nextSkillId == null) {
                            nextSkillId = lesson?.skills[index + 1]?._id
                        }
                    }
                })
            })
        })


    }, [])

    setTimeout(() => {
        setShowLoader(false)
    }, 3000);

    // if (loading || modules === undefined) {
    //     return (showLoader && <Loading />)
    // }
    return (
        <>
            {
                showModal &&
                <div className="journal-modal">
                    <Notes />
                </div>
            }
            <div className="mysidebar">
                <SideBar nextLink={nextLink} setNextLink={setNextLink} page="Skills" />
            </div>
            <RewardsModal open={open} setOpen={setOpen} />
            <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
            <div className="skills-container skill-redesign">
                <Grid container>
                    <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem", mt: 2, display: { sm: 'flex', xs: 'none' }, justifyContent: { sm: 'flex-start', xs: 'center' } }}>
                        <img src="/assets/img/bb-logo.svg" style={{ width: "180px" }} />
                    </Grid>
                </Grid>

                <div className="myrow" style={{ marginBottom: "0" }}>
                    <Link
                        to={`/lesson?id=${moduleID}`}
                        className="mybtn customizedbtn"
                        style={{ background: "transparent" }}
                    >
                        <ArrowBackIcon color="inherit" sx={{ mr: 1 }} />
                        {t("global.back")}
                    </Link>
                    {/* 
                        <div className="translate-buttons">

                            <Link to="/" className="translate-it"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTranslate(true)
                                }}>
                                Translate It
                            </Link>

                            <Link
                                to="/rewards"
                                className="completed-lessons"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpen(true);
                                }}
                            >
                                <span>
                                    {user.earnedPoints.toLocaleString()} / {user.totalPoints.toLocaleString()}
                                </span>
                                <LocalAtmRoundedIcon />
                            </Link>
                        </div> */}
                </div>
                <Grid container xs={12}
                    className="subskillsredesign__subscontain"
                    sx={{ flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'scroll', width: '87vw' }}
                >
                    <Grid item className="subskillsredesign__subscontaininner">
                        <Grid container gap={"1rem"} sx={{ flexWrap: { sm: 'nowrap', xs: 'wrap' } }}>
                            {
                                modules?.filter(module => { return (module._id === moduleID) })[0]
                                    ?.lessons?.filter(lesson => { return (lesson._id === lessonID) })[0]
                                    ?.skills?.filter(skil => { return (skil._id === skillID) })[0]
                                    ?.subSkills?.map((skill, index) => {
                                        return (<>
                                            <SkillOne
                                                moduleID={moduleID}
                                                lessonID={lessonID}
                                                skill={skill}
                                                skillID={skillID}
                                                keyIndex={index}
                                            />
                                        </>)
                                    })
                            }
                            {
                                modules?.filter(module => { return (module._id === moduleID) })[0]
                                    ?.lessons?.filter(lesson => { return (lesson._id === lessonID) })[0]
                                    ?.skills?.filter(skil => { return (skil._id === skillID) })[0]
                                    ?.subSkills?.length === 0 &&
                                <div className="col-4 d-flex justify-content-center align-items-center">
                                    <img width="100%" src="../assets/svg/lesson/no-data2.svg" alt="" />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    nextSkillId &&
                    <Link className="next" to={`/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${nextSkillId}`}>
                        <img src={nextSrc}
                            onMouseEnter={() => { setNextSrc("../../assets/svg/next.svg") }}
                            onMouseOut={() => { setNextSrc("../../assets/svg/next-outlined.svg") }} alt="" />
                    </Link>
                }
                <ChatBot
                    pageName={"SubSkills"}
                    data={
                        modules?.filter(module => { return (module._id === moduleID) })[0]
                            ?.lessons?.filter(lesson => { return (lesson._id === lessonID) })[0]
                            ?.skills?.filter(skil => { return (skil._id === skillID) })[0]
                            ?.subSkills?.map(sub => { return ({ name: sub.name, description: sub.description }) })
                    }
                />
                <button
                    onClick={openModal}
                    className="journal-button"
                >
                    {
                        showModal ?
                            <CloseIcon fontSize="large" />
                            :
                            <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#106EAC', marginTop: '-2px' }} icon={faBook} />
                    }
                </button>
            </div>
        </>
    )
}

