import React, { useState, useEffect, memo } from "react";
import { IconButton } from "@mui/material";
import axios from "axios";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import LazzyLoading from "../../src/components/common/LazzyLoading";
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
const PdfViewer = ({ pdfUrl, height }) => {

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


  // const [docs, setDocs] = useState("");
  // const [isLoaded, setIsLoaded] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  // const iframeDisplayStyle = isLoaded ? "block" : "none";

  // const defaultStyle = {
  //   height: height ? height : "500px",
  //   width: "100%",
  //   margin: "0 auto",
  //   border: "1px solid #dddddd",
  //   ...{ display: iframeDisplayStyle },
  // };

  // const getDocument = async () => {
  //   try {
  //     const response = await axios.get(pdfUrl, { responseType: "blob" });

  //     if (!response.status == 200) {
  //       throw new Error(`Request failed with status: ${response.status}`);
  //     }
  //     console.log("TEST SET", JSON.stringify(response.headers));
  //     const pdfText = response.data;

  //     const blob = new Blob([pdfText], { type: "application/pdf" });

  //     const fileURL = URL.createObjectURL(blob);

  //     setDocs(fileURL);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   getDocument();
  // }, [pdfUrl]);





  return (
    <div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      {/* <Document file={pdfUrl} /> */}

      <IconButton
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
        sx={{ ...btnStyle, left: '-30px' }}>
        <ArrowBackIcon sx={{ ...iconStyle }} />
      </IconButton>
      <IconButton
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
        sx={{ ...btnStyle, right: '-30px' }}>
        <ArrowForwardIcon sx={{ ...iconStyle }} />
      </IconButton>
    </div>
  );
};

export default memo(PdfViewer);
const iconStyle = {
  width: '40px',
  height: '40px',
  color: '#fff'
}

const btnStyle = {
  backgroundColor: 'rgba(97, 109, 237, 1)',
  zIndex: 1000,
  height: '50px',
  width: '50px',
  boxShadow: '0px 0px 10px 0px rgba(97, 109, 237, 0.5)',
  position: 'absolute',
  top: '50%',
  bottom: '50%',
  '&:hover': {
    boxShadow: '0px 0px 15px 0px rgba(97, 109, 237, 0.8)',
    backgroundColor: 'rgba(97, 109, 237, 0.9)',
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  }

}