import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "global": {
        "audio": 'Audio',
        "text": "Text",
        "uploadaudio": "Upload Audio",
        "spanish": "Spanish",
        english: "English",
        "back": "Back",
        "save": "Save",
        "duplicate": "Duplicate",
        "delete": "Delete",
        "noteDeleteWarning": "Are you sure you want to delete this Note ?",
        "journal": "Journal",
        "notes": "Notes",
        "noResultFound": "No Result Found",
        "enterNoteTitle": "Enter Title of Note",
        "enterQuestionTitle": "Enter Title of Question",
        "attachmentLink": "Attach Link",
        "attachmentPlaceholder": "Place your attachment link",
        "saved": "Saved",
        "new": "New",
        "home": "Home",
        "searchHere": "Search here quickly",
        "clickHereToCompose": "Click here to start typing",
        "completed": "Completed",
        "transcript": "Transcript",
        "loading": "Loading",
        "quizScore": "Quiz Score",
        "pageNotHere": "404: The page you are looking for isn't here",
        "404content": "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.",
        "backToModule": "Back to Module",
        "intro": "Intro",
        "assess": "Assess",
        "sims": "SIMS",
        "quiz": "Quiz",
        "financialEducation": "Financial Education Center",
        "backToBrooklineBank": "Back to Brookline Bank Website"
      },
      "loading": {
        "text": "We're getting things ready for you"
      },
      "module": {
        "btnStarted": "Get Started",
        "btnLearn": "Learn More",
        "btnSuccess": "Success Skills",
        "btnLogout": "Logout"
      },
      "lesson": {
        "progress": "Pogress",
        "earnedpoints": 'Points Earned',
        "totalpoints": "Total Points",
        "ok": "OK",
        "congrats": "Congratulations",
        "congratsText": "You have Conquered Brookline Bank",

      },
      "turn": {
        "gamemode": "Turn your phone to the side for game mode"
      },
      "feedback": {
        "howDoYouDo": "How Did You Do ?",
        "btnSubmit": "Submit"
      },
      "quizResult": {
        "quizResults": "Quiz Results",
        "completed": "Skill Section Completed",
        "pointsEarned": "Points Earned",
        "congratulationsOnCompletion": "Congratulations on completion",
        "btnNext": "Next",
        "results": "Results"
      },
      "Print Page": 'Print Page',
      "askAnything": "Ask Anything",
      online: "Online",
      enterQuestion: "Enter Question",
      typing: "typing",
      disclaimerText: `Disclaimer: Information on this website is made available to you only as self-help tools for your independent use and is not intended to provide financial or tax advice. We cannot and do not guarantee its applicability or accuracy in regard to your individual circumstances. All examples are for illustrative purposes only. We encourage you to seek personalized advice from qualified professionals regarding all personal or business finance issues.`,
      grade: "Grade",
      gradeLevel: "Grade Level",
      gradeUpdatedMessage: "Grade level updated to",
      emptyChatText1: `Ask Anything helps you with financial questions related to our course content. Simply type your question in the chat below, and an answer will appear. For questions about your Brookline Bank accounts or services, please contact a representative at 877-668-2265.`,
      emptyChatText2: `If you have questions about your Brookline Bank accounts or services, please speak with a representative at 877-668-2265.`,
      emptyChatText3: `It's easy and quick!`,
      retryQuiz: "Retry Quiz",
      help: "Help",
      needHelp: "Need Help?",
      supportMail: "For technical support with Stickball course content, please email:",
      supportExplanation: "Please provide an explanation of the issue you are having as well as the following information below as part of your support request:",
      helpOption1: "Operating System: Windows",
      helpOption2: "Browser: Mozilla/5.0 (Windows NT 10.0; Win64;x64) AppleWebKit/537.36 (KHTML, Like Gecko) Chrome/125.0.0.0 Safari/537.36",
      helpOption3: "Device: Win32",
      ok: "OK",
      popupText1: "If you clear your browser cache, you will lose all the progress you’ve made and information you have entered in the Financial Education Center. To avoid this, please refrain from clearing your cache.",
      popupText2: "For guidance in the Financial Education Center, click the “?” button in the bottom left to view a demonstration. This will show you how to navigate and make the most of each section.",
      attention: "Attention",
      beforeYouBegin: "Before You Begin",
      itGetIt: "I Get It",
      confused: "Confused",
      tooComplex: "Too Complex",

    }
  },
  sp: {
    translation: {
      "global": {
        "audio": 'Audio',
        "text": "Texto",
        "uploadaudio": "Subir Audio",
        "spanish": "Español",
        english: "Inglés",
        "save": "Ahorrar",
        "duplicate": "Duplicada",
        "back": "Atrás",
        "delete": "Borrar",
        "noteDeleteWarning": "Estás seguro que quieres eliminar esta nota ?",
        "journal": "Diario",
        "notes": "Notas",
        "noResultFound": "No se han encontrado resultados",
        "enterNoteTitle": "Ingrese el título de la nota",
        "enterQuestionTitle": "Ingrese el título de la pregunta",
        "attachmentLink": "Adjuntar enlace",
        "attachmentPlaceholder": "Coloque su enlace adjunto",
        "saved": "Salvado",
        "new": "Nueva",
        "home": "Hogar",
        "searchHere": "Busca aquí rápidamente",
        "clickHereToCompose": "Haga clic aquí para comenzar a escribir",
        "completed": "Terminada",
        "transcript": "Transcripción",
        "loading": "Cargando",
        "quizScore": "Puntuación del examen",
        "pageNotHere": "404: La página que buscas no está aquí",
        "404content": "O intentaste alguna ruta turbia o viniste aquí por error. Sea lo que sea, intenta utilizar la navegación.",
        "backToModule": "Volver al módulo",
        "intro": "Introducción",
        "assess": "Evaluar",
        "sims": "SIMS",
        "quiz": "Prueba",
        "financialEducation": "Centro de Educación Financiera",
        "backToBrooklineBank": "Volver al sitio web de Brookline Bank"
      },
      "loading": {
        "text": "Estamos preparando las cosas para ti."
      },
      "module": {
        "btnStarted": "Empieza",
        "btnLearn": "Más información",
        "btnSuccess": "Habilidades de éxito",
        "btnLogout": "Cerrar sesión"
      },
      "lesson": {
        "progress": "progreso",
        "earnedpoints": 'Puntos ganados',
        "totalpoints": "Puntos totales",
        "ok": "DE ACUERDO",
        "congrats": "Felicidades",
        "congratsText": "Has conquistado Brookline Bank",
      },
      "turn": {
        "gamemode": "Gire el teléfono al lado para el modo de juego"
      },
      "feedback": {
        "howDoYouDo": "¿Cómo estás?",
        "btnSubmit": "Presentar"
      },
      "quizResult": {
        "quizResults": "Resultados de Quiz",
        "completed": "Skill Section Completado",
        "pointsEarned": "Puntos pendientes",
        "congratulationsOnCompletion": "Felicitaciones por completar",
        "btnNext": "Siguiente",
        "results": "Resultados"
      },
      "termsConditions": {
        "heading": "Terms and Conditions",

      },
      "Print Page": 'Imprimir página',
      "askAnything": "Pregunta cualquier cosa",
      online: "En línea",
      enterQuestion: "Introducir pregunta",
      typing: "mecanografía",
      disclaimerText: `Descargo de responsabilidad: La información contenida en este sitio web se pone a su disposición únicamente como herramientas de autoayuda para su uso independiente y no pretende proporcionar asesoramiento financiero o fiscal. No podemos garantizar ni garantizamos su aplicabilidad o precisión con respecto a sus circunstancias individuales. Todos los ejemplos tienen únicamente fines ilustrativos. Le recomendamos que busque asesoramiento personalizado de profesionales calificados con respecto a todos los temas de finanzas personales o comerciales.`,
      grade: "Calificación",
      gradeLevel: "Nivel de grado",
      gradeUpdatedMessage: "Nivel de grado actualizado a",
      emptyChatText1: `Ask Anything le ayuda con preguntas financieras relacionadas con el contenido de nuestro curso. Simplemente escriba su pregunta en el chat a continuación y aparecerá una respuesta. Si tiene preguntas sobre sus cuentas o servicios de Brookline Bank, comuníquese con un representante al 877-668-2265.`,
      emptyChatText2: `Si tiene preguntas sobre sus cuentas o servicios de Brookline Bank, hable con un representante al 877-668-2265.`,
      emptyChatText3: `¡Es fácil y rápido!`,
      retryQuiz: "Reintentar prueba",
      help: "Ayuda",
      needHelp: "¿Necesitas ayuda?",
      supportMail: "Para obtener asistencia técnica con el contenido del curso Stickball, envíe un correo electrónico:",
      supportExplanation: "Proporcione una explicación del problema que tiene, así como la siguiente información a continuación como parte de su solicitud de soporte:",
      helpOption1: "Sistema operativo: Windows",
      helpOption2: "Navegador: Mozilla/5.0 (Windows NT 10.0; Win64;x64) AppleWebKit/537.36 (KHTML, como Gecko) Chrome/125.0.0.0 Safari/537.36",
      helpOption3: "Dispositivo: Win32",
      ok: "Bueno",
      popupText1: "Si borra el caché de su navegador, perderá todo el progreso que haya realizado y la información que haya ingresado en el Centro de Educación Financiera. Para evitar esto, absténgase de borrar su caché.",
      popupText2: 'Para obtener orientación en el Centro de Educación Financiera, haga clic en el botón "?" en la parte inferior izquierda para ver una demostración. Esto le mostrará cómo navegar y aprovechar al máximo cada sección.',
      attention: "Atención",
      beforeYouBegin: "Antes de que empieces",
      itGetIt: "Lo Entiendo",
      confused: "Confundida",
      tooComplex: "Demasiado Compleja",
    }
  },
  ht: {
    translation: {
      "loading": {
        "text": "Nou ap prepare bagay yo pou ou"
      },
      "module": {
        "btnStarted": "Kòmanse",
        "btnLearn": "Aprann plis",
        "btnSuccess": "Konpetans siksè",
        "btnLogout": "Dekonekte"
      },
      "turn": {
        "gamemode": "Vire telefòn ou sou bò pou mòd jwèt"
      },
      "feedback": {
        "howDoYouDo": "Kijan ou te fè?",
        "btnSubmit": "Soumèt"
      },
      "quizResult": {
        "quizResults": "Rezilta egzamen",
        "completed": "Konplete",
        "pointsEarned": "Pwen ou touche",
        "congratulationsOnCompletion": "Felisitasyon pou fini",
        "btnNext": "Next",
      },
      "Print Page": 'Enprime paj'
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || "en",
    fallbackLng: "en", // Use English if the translation key is missing
    debug: true, // Enable debug mode to log detailed information
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;