
import { Box, CircularProgress, Typography } from "@mui/material";

function CircularProgressWithLabel(props) {

    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="primary"
                >{(props.value / props.divident).toFixed(0)}</Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel