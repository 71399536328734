import React from 'react'
import { Box, useMediaQuery, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import RecordVideo from './video-recorder'
export default function QRScaned() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    if (!isMobile) return <Box sx={{ width: "100%", mt: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h6" color="initial">
            This page is created for mobiles only. Please scan the QR with Mobile to continue...
        </Typography>
        <img width={400} src="./assets/img/qr.png" alt="QR Code" />
    </Box>
    return (
        <>
        <RecordVideo  />
        </>
    )
    return (
        <div>index</div>
    )
}
