import React, { useState, useEffect } from 'react'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
    IconButton,
    useMediaQuery,
    Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useLocation } from 'react-router-dom';

const generateTutorialData = (pathname, language, page) => {
    if (language == "sp") {
        if (page == "askAnything") {
            return [
                'assets/img/tutorial/spa/aa1.png',
                'assets/img/tutorial/spa/aa2.png',
                'assets/img/tutorial/spa/aa3.png',
            ]
        }
        if (pathname == "/module") {
            return [
                'assets/img/tutorial/spa/welcome.png',
            ]
        }
        if (pathname == "/lesson") {
            return [
                'assets/img/tutorial/spa/fl-1.png',
                'assets/img/tutorial/spa/fl-2.png',
                'assets/img/tutorial/spa/fl-3.png',
                'assets/img/tutorial/spa/fl-4.png',
                'assets/img/tutorial/spa/fl-5.png',
                'assets/img/tutorial/spa/fl-6.png',
                'assets/img/tutorial/spa/fl-7.png',
            ]
        }
        if (pathname == "/skills") {
            return [
                'assets/img/tutorial/spa/fl-3.png',
                'assets/img/tutorial/spa/fl-4.png',
                'assets/img/tutorial/spa/fl-5.png',
                'assets/img/tutorial/spa/fl-6.png',
                'assets/img/tutorial/spa/fl-7.png',
            ]
        }
        return [
            'assets/img/tutorial/spa/fl-4.png',
            'assets/img/tutorial/spa/fl-5.png',
            'assets/img/tutorial/spa/fl-6.png',
            'assets/img/tutorial/spa/fl-7.png',
        ]
    } else {
        if (page == "askAnything") {
            return [
                'assets/img/tutorial/eng/aa1.png',
                'assets/img/tutorial/eng/aa2.png',
                'assets/img/tutorial/eng/aa3.png',
            ]
        }
        if (pathname == "/module") {
            return [
                'assets/img/tutorial/eng/welcome.png',
            ]
        }
        if (pathname == "/lesson") {
            return [
                'assets/img/tutorial/eng/fl-1.png',
                'assets/img/tutorial/eng/fl-2.png',
                'assets/img/tutorial/eng/fl-3.png',
                'assets/img/tutorial/eng/fl-4.png',
                'assets/img/tutorial/eng/fl-5.png',
                'assets/img/tutorial/eng/fl-6.png',
                'assets/img/tutorial/eng/fl-7.png',
            ]
        }
        if (pathname == "/skills") {
            return [
                'assets/img/tutorial/eng/fl-3.png',
                'assets/img/tutorial/eng/fl-4.png',
                'assets/img/tutorial/eng/fl-5.png',
                'assets/img/tutorial/eng/fl-6.png',
                'assets/img/tutorial/eng/fl-7.png',
            ]
        }
        return [
            'assets/img/tutorial/eng/fl-4.png',
            'assets/img/tutorial/eng/fl-5.png',
            'assets/img/tutorial/eng/fl-6.png',
            'assets/img/tutorial/eng/fl-7.png',
        ]
    }
}

const isVideo = (link) => {
    let linkSplited = link.split(".")
    if (linkSplited[linkSplited?.length - 1] === "mp4") {
        return true
    } else {
        return false
    }
}

export default function Tutorial({ page }) {
    const location = useLocation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    let tutData = generateTutorialData(location.pathname, localStorage.getItem("lang"), page)
    const [open, setOpen] = useState(false)

    const tutBtnStyle = {
        width: { sm: "35px", xs: '25px' },
        height: { sm: "35px", xs: '25px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
        borderRadius: '50%',
        backgroundColor: '#fff',
        cursor: 'pointer'

    }
    useEffect(() => {
        let firstVisit = localStorage.getItem('firstVisit')
        if (!firstVisit) {
            setTimeout(()=>{
                setOpen(true)
            }, 5000)
        }
    }, [])

    return (
        <>
            <IconButton sx={tutBtnStyle} onClick={() => setOpen(true)}>
                <QuestionMarkIcon style={{ width: { sm: "25px", xs: '15px' }, height: { sm: "25px", xs: '15px' } }} />
            </IconButton>
            <Dialog
                fullWidth
                open={open}
                onClose={() => {
                    setOpen(false)
                    localStorage.setItem('firstVisit', "visited")
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    sx: {
                        margin: { sm: 5, xs: .5 },
                        width: '100%'
                    }
                }}
            >
                <Box id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        {/* Tutorial */}
                    </span>
                    <IconButton onClick={() => {
                        setOpen(false)
                        localStorage.setItem('firstVisit', "visited")
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Box >
                <DialogContent sx={{ display: 'flex', justifyContent: 'center', p: { sm: "32px", xs: "10px" } }}>
                    <CarouselProvider
                        style={{ width: "100%" }}
                        naturalSlideWidth={"100%"}
                        naturalSlideHeight={isMobile ? "200px" : "500px"}
                        totalSlides={tutData?.length}
                        visibleSlides={1}
                    >
                        <Slider>
                            {
                                tutData?.map((item, index) => {
                                    return (
                                        <Slide
                                            style={{
                                                marginLeft: '0px',
                                                borderRadius: '15px',
                                                height: isMobile ? "200px" : isVideo(item) ? "400px" : "500px",
                                            }}
                                            index={index}
                                            key={index}
                                        >
                                            {
                                                isVideo(item) ?
                                                    <video
                                                        controls
                                                        autoPlay={index == 0}
                                                        width='100%'
                                                        height={isMobile ? "200px" : "400px"}
                                                        playsInline
                                                        alt="All the devices"
                                                        src={item}
                                                    ></video>
                                                    :
                                                    <img width="100%" src={item} alt="" />
                                            }

                                        </Slide>
                                    )
                                })
                            }
                        </Slider>
                        {tutData.length > 1 && <div style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                            <ButtonBack style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <IconButton>
                                    <ArrowBackIosRoundedIcon />
                                </IconButton>
                            </ButtonBack>
                            <ButtonNext style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <IconButton>
                                    <ArrowForwardIosRoundedIcon />
                                </IconButton>
                            </ButtonNext>
                        </div>}
                    </CarouselProvider>
                </DialogContent>
            </Dialog >
        </>
    )
}
