import axios from 'axios'
export const generateScript = async (title) => { // Pass newMessages as a parameter

    const apiKey = process.env.REACT_APP_OPENAI_API_KEY; // Replace with your OpenAI API key

    const prompt = `Create a paragraph of 50 words for ${title}. I will be using this for recoding my self to generate a good video. So provide a text that shoud have good impact in vidoe`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [{ role: 'system', content: 'You are a helpful assistant.' }, { role: 'user', content: prompt }]
        })
    });

    const data = await response.json();
    return (data.choices[0].message.content);

}
