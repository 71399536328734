/*----------------------------------------------------------------
  Handling files upload to S3 
  ----------------------------------------------------------------*/
import AWS from "aws-sdk";
import toast from 'react-hot-toast'
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export const uploadToStickS3 = async (media) => {
    let uploadloading = toast.loading("Uploading File...");
    let name = media?.name?.replace(/ /g, "-")
    const params = {
        ACL: "public-read",
        Body: media,
        Bucket: S3_BUCKET,
        Key: "the-stick/" + name,
    }
    try {
        await myBucket.putObject(params).promise()
        toast.dismiss(uploadloading);
        toast.success("Your Video Submitted");
        return (name)
    } catch (err) {
        if (err) {
            toast.dismiss(uploadloading);
            console.log("Error: ", err)
            return null
        }
    }
}