import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SideBar from "./details/SideBar";
import { useSelector } from "react-redux";
import Loading from "../common/Loading";
import RewardsModal from "./details/RewardsModal";
import TranslateModal from "./details/TranslateModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from './notes/Notes'
import CloseIcon from '@mui/icons-material/Close';
import Enterpreneur from './Entrepreneur.js'
import Button from '@mui/material/Button';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid } from "@mui/material"
import ChatBot from "../common/chatbot.js";
import { useTranslation } from 'react-i18next'

export default function Lesson(props) {
  const { t } = useTranslation()
  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");
  const [nextLink, setNextLink] = useState(id);
  const [moduleContent, setModuleContent] = useState();
  const modules = useSelector((state) => state.user.userData?.data);
  const userCompleted = useSelector((state) => state?.user?.userCompleted);
  const [open, setOpen] = useState(false);
  const [openTranslate, setOpenTranslate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentLesson, setCurrentLesson] = useState('');
  const [currentSkill, setCurrentSkill] = useState('');
  const [nextSkill, setNextSkill] = useState('');
  const [showLoader, setShowLoader] = useState(true);

  const openModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    modules?.map((module) => {
      if (module._id === nextLink) {
        setModuleContent(module);
        return;
      }
    });
    return 1;
  }, [modules, nextLink]);

  useEffect(() => {
    for (let i = 0; i < moduleContent?.lessons?.length; i++) {
      if (!userCompleted?.includes(moduleContent?.lessons[i]?._id)) {
        setCurrentLesson(moduleContent?.lessons[i])
        return;
      }
    }

  }, [moduleContent])

  useEffect(() => {
    for (let i = 0; i < currentLesson?.skills?.length; i++) {
      if (!userCompleted?.includes(currentLesson?.skills[i]?._id)) {
        setCurrentSkill(currentLesson?.skills[i])
        setNextSkill(currentLesson?.skills[i + 1])
        return;
      }
    }
  }, [currentLesson])

  setTimeout(() => {
    setShowLoader(false)
  }, 3000);

  // if (modules === undefined) {
  //   return (showLoader && <Loading />);
  // }
  return (
    <div  >
      <RewardsModal open={open} setOpen={setOpen} />
      <SideBar nextLink={nextLink} setNextLink={setNextLink} page="Lesson" />
      <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
      {
        showModal &&
        <div className="journal-modal">
          <Notes />
        </div>
      }
      <div className="lesson-container" style={{ overflow: "visible" }}>
        <Grid container>
          <Grid xs={12} sm={8} container sx={{ mt: 3, display: { sm: 'flex', xs: 'none' }, justifyContent: { sm: 'flex-start', xs: 'center' } }}>
            <img src="/assets/img/bb-logo.svg" style={{ width: "180px" }} />
          </Grid>
        </Grid>
        <div className="myrow">
          <div className="text-heading">
            <div className="row d-flex justify-content-center mt-1">
              <Link
                to="/module"
                className="mybtn customizedbtn"
                style={{ background: "transparent" }}
              >
                <ArrowBackIcon color="inherit" sx={{ mr: 1 }} />
                {t("global.back")}
              </Link>
            </div>
          </div>
        </div>
        <div className="myrow">
          {
            moduleContent?.lessons.length ?
              <>
                {
                  userCompleted?.includes(moduleContent?._id) ?
                    <>
                      <div className="bottom_bar">
                        <div className="inner_card">
                          <div className="small-card">
                            <div className="first">
                              <h2>{t("lesson.congrats")}</h2>
                              <p>{t("lesson.congratsText")}</p>
                            </div>
                            <div className="second">
                              <CelebrationIcon sx={{ color: "#106EAC", fontSize: "50px" }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="bottom_bar">
                        <div className="inner_card">
                          <div className="small-card">
                            <div className="first">
                              <h2>{currentSkill?.name}</h2>
                              <p>{nextSkill?.name}</p>
                            </div>
                            <div className="second">
                              <Button variant="outlined">
                                <Link
                                  style={{ color: 'inherit' }}
                                  to={"/skills?moduleID=" + moduleContent?._id + "&lessonID=" + currentLesson._id + "&skillID=" + currentSkill?._id} >
                                  {t("module.btnStarted")}
                                </Link>

                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                }
              </>
              : <></>
          }
        </div>
        <div
          className="lesson-content d-flex  flex-column align-items-center"
          style={{ width: "100%", height: "fit-content" }}
        >
          <Enterpreneur module={moduleContent} currentSkill={currentSkill} nextSkill={nextSkill} currentLesson={currentLesson} moduleID={moduleContent?._id} />
        </div>
        <button
          onClick={openModal}
          className="journal-button"
        >
          {
            showModal ?
              <CloseIcon fontSize="large" />
              :
              <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#106EAC', marginTop: '-2px' }} icon={faBook} />
          }
        </button>
        <ChatBot
          data={moduleContent?.lessons?.map(lesson => {
            return (
              {
                name: lesson.name,
                skills: lesson?.skills?.map(skill => skill?.name)
              }
            )
          })}
          pageName={"Lesson"}
        />
      </div>
    </div>
  );
}
