import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import WarningModal from "../../modals/WarningModal";
import { addNote, updateNote, deleteNote } from "../../../redux-features/user/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'


const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 270,
  bgcolor: "background.paper",
  p: 3,
  borderRadius: 5,
};
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.7rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    position: "relative",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: "0px",
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
export default function Notes() {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [type, setType] = useState("");
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [bread1, setBread1] = useState("");
  const [bread2, setBread2] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [noteID, setNoteID] = useState("");
  const open1 = Boolean(anchorEl);
  const [opens11, setOpens11] = useState(false);
  const handleCloses11 = () => setOpens11(false);
  const [expanded, setExpanded] = useState("panel1");
  const [link, setLink] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const notesData = useSelector(state => state.user.notes)

  useEffect(() => {
    if (notesData?.length) {
      setData(notesData)
    }
  }, [notesData])

  useEffect(() => {
    if (isMobile) {
      setOpen(false)
    }
  }, [isMobile])
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    toast.loading("Deleting...");
    dispatch(deleteNote(noteID));
    toast.dismiss();
    toast.success("Deleted");
    handleCloseModal();
    setAnchorEl(null);
    setNoteContent("");
    setNoteTitle("");
    setType("");
    setIsAdd(false);
    setIsUpdate(false);
    setBread1("");
    setBread2("");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleAddNote = (type) => {
    setType(type);
    setIsAdd(true);
    setIsUpdate(false);
    setNoteContent("");
    setNoteTitle("");
    setBread1(type === "notes" ? t("global.notes") : "Questions");
    setBread2("");
    setLink("");
  };
  const handleSaveNote = () => {
    toast.loading("Saving...");
    if (isAdd && !isUpdate) {
      let id = uuidv4()
      let temp = {
        _id: id,
        type: type,
        content: noteContent,
        title: noteTitle,
        attachment: link,
      };
      dispatch(addNote(temp));
      setNoteID(id)
      toast.dismiss();
      setOpens11(true);
      setTimeout(() => {
        setOpens11(false);
      }, 1000);
    }
    if (isUpdate) {
      let temp = {
        type: type,
        _id: noteID,
        title: noteTitle,
        content: noteContent,
        attachment: link,
      };
      dispatch(updateNote(temp));
      toast.dismiss();
      setOpens11(true);
      setTimeout(() => {
        setOpens11(false);
      }, 1000);
    }
    setIsUpdate(true);
    setIsAdd(false);
    setBread2(noteTitle);
  };
  const filterData = (value) => {
    let tempVal = value.toLowerCase();
    let temp = notesData?.filter((el) => {
      let item = el?.title.toLowerCase();
      let find = item?.search(tempVal);
      if (find === -1) {
        return false;
      } else {
        return true;
      }
    });
    setData(temp);
  };
  return (
    <Grid
      theme={theme}
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        margin: "0px",
        padding: 0,
        overflow: "hidden",
        color: "#000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          overflowY: "auto",
          padding: 0,
          position: "relative",
        }}
      >
        <CssBaseline />
        <AppBar className="app-bar" position="absolute" open={open}>
          <Toolbar sx={{ width: "fit-content" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">{bread2}</Typography>
            </Breadcrumbs>
          </Toolbar>
          <div className="journal-buttons">
            <Tooltip title={t("global.new")}>
              <button className="menu" onClick={() => handleAddNote("notes")}>
                <AddIcon />
              </button>
            </Tooltip>

            {(isUpdate || isAdd) && (
              <>
                {isAdd && !!noteTitle && (
                  <Tooltip title={t("global.save")}>
                    <button className="menu" onClick={handleSaveNote}>
                      <SaveIcon />
                    </button>
                  </Tooltip>
                )}

                {isUpdate && (
                  <>
                    <button className="menu" onClick={handleClick}>
                      <MoreHorizIcon />
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </AppBar>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open1}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem disabled onClick={handleClose}>
            {t("global.duplicate")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {t("global.delete")}
          </MenuItem>
        </Menu>
        <WarningModal
          content={t("global.noteDeleteWarning")}
          handleDelete={handleDelete}
          openModal={openModal}
          handleClose={handleCloseModal}
        />

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              position: "relative",
              margin: 0,
              height: isMobile ? "72vh" : "76vh",
              boxShadow: "1px 0px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              overflowY: "auto",
            },
            padding: "0px",
            position: "relative",
            height: "100%",
            overflowY: "auto",
          }}
          className="side_bar_jour"
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader
            sx={{ margin: 0, display: "flex", justifyContent: "space-between" }}
            style={{ maxHeight: "30px" }}
          >
            <Typography variant="h6">{t("global.journal")}</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <input
            type="text"
            className="journal-search"
            placeholder={t("global.searchHere")}
            onChange={(e) => filterData(e.target.value)}
          />
          <List className="side-list">
            {data?.map((text, index) => {
              return (
                text?.type === "notes" && (
                  <ListItem
                    className="journal-list-item"
                    key={text?.id}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        if (isMobile) {
                          setOpen(false)
                        }
                        setBread1(t("global.notes"));
                        setBread2(text?.title || "Untitled");
                        setNoteTitle(text?.title || "");
                        setNoteContent(text?.content || "");
                        setType(text?.type);
                        setIsUpdate(true);
                        setNoteID(text?._id);
                        setIsAdd(false);
                        setLink(text?.attachment || "");
                      }}
                    >
                      <ListItemIcon className="list-icon">
                        <TextSnippetIcon style={{ color: "#829d00" }} />
                      </ListItemIcon>
                      <ListItemText
                        fontSize={10}
                        primary={
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: "12px",
                            }}
                          >
                            {text?.title || "Untitled"}
                          </p>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )
              );
            })}
            {!data?.filter((x) => x.type === "notes").length && (
              <ListItem className="journal-list-item" disablePadding>
                <ListItemButton>
                  <ListItemText
                    fontSize={10}
                    primary={
                      <p
                        style={{ margin: 0, padding: 0, fontSize: "12px" }}
                      >
                        {t("global.noResultFound")}
                      </p>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Drawer>
        <Main open={open} className="main-journal" onClick={() => {
          if (isMobile) {
            setOpen(false)
          }
        }}>
          <DrawerHeader />
          {(isAdd || isUpdate) ? (
            <div className="main-area">
              <input
                type="text"
                className="journal-title"
                placeholder={
                  type === "notes"
                    ? t("global.enterNoteTitle")
                    : t("global.enterQuestionTitle")
                }
                onChange={(e) => {
                  if (isUpdate) {
                    setIsAdd(true);
                  }
                  setNoteTitle(e.target.value);
                }}
                value={noteTitle}
              />
              <textarea
                rows={10}
                className="journal-text"
                placeholder={t("global.clickHereToCompose")}
                onChange={(e) => {
                  if (isUpdate) {
                    setIsAdd(true);
                  }
                  setNoteContent(e.target.value);
                }}
                value={noteContent}
              />
            </div>
          ) : (
            <div
              className="main-area d-flex justify-content-center align-items-center"
              style={{ height: "80%" }}
            >
              <img
                className="btn-add-journal"
                onClick={() => handleAddNote("notes")}
                width="30%"
                src="assets/img/blank-screen.svg"
                alt=""
              />
            </div>
          )}
        </Main>
      </Box>
      {/* save modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opens11}
        onClose={handleCloses11}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens11}>
          <Box sx={style1}>
            <div className="text-center">
              <h2 className="fw-bold">{t("global.saved")}</h2>
              <CheckCircleIcon className="check_save" />
            </div>
          </Box>
        </Fade>
      </Modal>

    </Grid>
  );
}
