import React, { useState } from 'react'
import { Box, Button, TextField, Typography, useMediaQuery, IconButton } from '@mui/material'
import { useTheme } from "@mui/material/styles"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { uploadToStickS3 } from '../qr-scaned/S3Services';
import RecordVideo from '../qr-scaned/video-recorder';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { QuillEditor } from "../lessons/details/quil-editor";
import { generateScript } from '../_api/the-stick';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';

export default function TheStick({ content }) {
    content = content?.allContent || {}
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [openModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState("")
    const [script, setScript] = useState("")
    const [type, setType] = useState(null)
    const [fileUrl, setFileUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = () => {
        setOpenModal(false)
        setType(null)
        setOpenModal(false)
        setFileUrl("")
    }
    const handleOpen = () => setOpenModal(true)
    const handleUpload = async (e) => {
        let res = await uploadToStickS3(e.target.files[0])
        setFileUrl(res)
    }

    const createScript = async () => {
        setIsLoading(true)
        toast.loading("Creating Script, Please wait...")
        let script = await generateScript(title)
        setScript(script)
        setIsLoading(false)
        toast.dismiss()
        toast.success(`Script created successfully`)
        setTitle("")
    }
    return (
        <>
            <Box sx={{ px: { lg: 20, md: 0 } }}>
                <QuillEditor sx={quilStyle} value={content?.name || ""} readOnly={true} />
                <QuillEditor
                    sx={quilStyle}
                    value={content?.description || ""}
                    readOnly={true}
                />
            </Box>
            <Box sx={boxStyle}>
                <Button variant='contained' sx={btnStyle} onClick={() => {
                    handleOpen()
                    setType("record")
                }}>
                    <Typography variant="h5" color="light" fontWeight={700}>
                        Record Myself
                    </Typography>
                </Button>
                <Button variant='contained' sx={btnStyle} >
                    <Typography variant="h5" color="light" fontWeight={700}>
                        {fileUrl ? "Update Video" : "Upload Video"}
                    </Typography>
                    <input accept='video/mp4' type="file" style={invisibleInput} onChange={handleUpload} />
                </Button>
                <Button variant='contained' sx={btnStyle} onClick={() => {
                    handleOpen()
                    setType("script")
                }}>
                    <Typography variant="h5" color="light" fontWeight={700}>
                        Video Script
                    </Typography>
                </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                {
                    fileUrl ?
                        <video width="330px" controls playing src={process.env.REACT_APP_S3_BUCKET_LINK + "the-stick/" + fileUrl}></video>
                        :
                        <></>
                }
            </Box>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={openModal}
                onClose={handleClose}
            >
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {
                            type == "script" ?
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    {
                                        !!script ?
                                            <>
                                                <Typography variant="body1" color="initial">
                                                    {script}
                                                    <IconButton onClick={() => {
                                                        navigator.clipboard.writeText(script)
                                                            .then(() => {
                                                                toast.success("Copied!")
                                                                handleClose()
                                                            })
                                                    }}>
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Typography>
                                                <Button
                                                    onClick={() => setScript("")}
                                                    variant='contained'
                                                    sx={{ textTransform: "capitalize", borderRadius: '10px', mt: 2 }}
                                                >Change Script</Button>
                                            </>
                                            :
                                            <>
                                                <TextField
                                                    disabled={isLoading}
                                                    multiline
                                                    minRows={5}
                                                    fullWidth
                                                    label="Enter video title to generate a script"
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    value={title}
                                                    sx={{ mt: 3 }}
                                                />
                                                <Button
                                                    disabled={isLoading}
                                                    onClick={createScript}
                                                    variant='contained'
                                                    sx={{ textTransform: "capitalize", borderRadius: '10px', mt: 2 }}
                                                >Submit</Button>
                                            </>
                                    }
                                </Box>
                                :
                                <>

                                    {
                                        !isMobile ?
                                            <img width={400} src="./assets/img/qr.png" alt="QR Scanner" />
                                            :
                                            <RecordVideo />
                                    }
                                </>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

const boxStyle = {
    mt: 5,
    width: "100%",
    display: 'flex',
    flexDirection: { md: "row", xs: "column" },
    gap: "30px",
    justifyContent: "center",
    alignItems: "center"
}
const btnStyle = {
    minHeight: "150px",
    width: "300px",
    textTransform: "capitalize",
    borderRadius: "10px",
    position: "relative"
}
const invisibleInput = {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    opacity: 0,
    cursor: "pointer"
}
const quilStyle = {
    fontSize: "16px",
    marginBottom: "20px",
    marginTop: "20px",
};