const config = {
    palette: {
        primary: {
            main: "#106EAC",
            light: "#d9ecf6",
            dark: "#115c82",
            contrastText: '#fff',
        },
        secondary: {
            main: "#829d00",
            light: "#e8f5ab",
            dark: "#7b9404",
            contrastText: "#fff",
        },
        light: {
            main: "#fff"
        }
    }
}

export default config