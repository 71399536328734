import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { QuillEditor } from '../details/quil-editor'

function Row(props) {
    const { row, index, Adminwrapper, DATA_TYPE, data, setData } = props;
    const [open, setOpen] = React.useState(false);
    const { QUILL, LINK } = DATA_TYPE
    return (
        <>
            <Adminwrapper dataType={LINK} data={data} setData={setData} type="dropdownTitle" gridIndex={index} label="Title" >
                <TableRow className="dropdown-row" sx={{ '& > *': { borderBottom: 'unset' } }} fullWidth hover onClick={() => setOpen(!open)}>
                    <TableCell component="th" fullWidth style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                            {index + 1 + ". "} {row.title}
                        </Typography>
                        <IconButton
                            className="dropdown-button"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
            </Adminwrapper>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} fullWidth>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: '10px 10px 10px 0px'}}>
                            <Adminwrapper dataType={QUILL} data={data} setData={setData} type="dropdownDetail" gridIndex={index} >
                                <QuillEditor
                                    readOnly={true}
                                    value={row.detail}
                                    sx={{ height: 'fit-content' }}
                                />
                            </Adminwrapper>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


export default function CollapsibleTable(props) {
    const { Adminwrapper, DATA_TYPE, data, setData } = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableBody>
                    {data?.dropdowns?.map((row, index) => (
                        <Row
                            key={row.name}
                            row={row}
                            index={index}
                            Adminwrapper={Adminwrapper}
                            DATA_TYPE={DATA_TYPE}
                            data={data}
                            setData={setData}
                        />
                    ))}
                    {!data?.dropdowns?.length && "No Dropdowns to show"}
                </TableBody>
            </Table>
        </TableContainer>
    );
}