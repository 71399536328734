import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';

const initialState = {
    userData: [],
    skillSections: [],
    quiz: {},
    moduleID: "",
    lessonID: "",
    skillID: "",
    skillName: "",
    subSkillID: "",
    subSkillName: "",
    session: "",
    quizPoints: 0,
    loading: false,
    moduleName: "",
    lessonName: "",
    chatbot: {},
    lesson: {},
    module: {},
    skill: {},
    userCompleted: [],
    notes: [],
    subSkillDescription: ""
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.userData = action.payload
            state.loading = false
            state.userData?.data?.map(module => {
                if (module._id === state.moduleID) {
                    state.module = module
                    module?.lessons?.map(lesson => {
                        if (lesson?._id === state.lessonID) {
                            state.lesson = lesson
                            lesson?.skills?.map(skill => {
                                if (skill._id === state.skillID) {
                                    state.skillName = skill?.name;
                                    state.skill = skill
                                    skill?.subSkills?.map(subSkill => {
                                        if (subSkill._id === state.subSkillID) {
                                            state.subSkillName = subSkill?.name;

                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        },
        setSkillSections: (state, action) => {
            let temp = []
            state.quiz = action.payload.quiz
            if (action.payload.skillSections[0].isCompleted) {
                state.skillSections = action.payload.skillSections
            } else {
                action.payload.skillSections.map((ss, i) => {
                    if (i === 0) {
                        temp.push({
                            _id: ss._id,
                            name: ss.name,
                            allContent: ss.allContent,
                            isEnrolled: true,
                            isCompleted: false,
                        })
                    } else {
                        temp.push({
                            _id: ss._id,
                            name: ss.name,
                            allContent: ss.allContent,
                            isEnrolled: false,
                            isCompleted: false,
                        })
                    }
                })
                state.skillSections = temp;
            }

        },
        setIDs: (state, action) => {
            state.moduleID = action.payload.module
            state.lessonID = action.payload.lesson
            state.skillID = action.payload.skill
            state.subSkillID = action.payload.subSkill
        },
        setSession: (state, action) => {
            state.session = action.payload
        },
        setSkill: (state, action) => {
            state.skill = action.payload
        },
        setSkillName: (state, action) => {
            state.skillName = action.payload
        },
        setSubSkillName: (state, action) => {
            state.subSkillName = action.payload
        },
        setSubSkillDescription: (state, action) => {
            state.subSkillDescription = action.payload
        },
        setQuizPoints: (state, action) => {
            state.quizPoints += action.payload
        },
        emptyPoints: (state) => {
            state.quizPoints = 0
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setModuleName: (state, action) => {
            state.moduleName = action.payload
        },
        setLessonName: (state, action) => {
            state.lessonName = action.payload
        },
        setChatbot: (state, action) => {
            state.chatbot = action.payload
        },
        markCompleted: (state, action) => {
            let completeData = state.userCompleted || []
            const sub_skill_id = action.payload
            let modules = state.userData.data
            completeData.push(sub_skill_id)
            modules.map((module) => {
                module?.lessons?.map((lesson, lessonIndex) => {
                    lesson.skills.map((skill, skillIndex) => {
                        skill.subSkills.map((subSkill, index) => {
                            if (subSkill._id === sub_skill_id) {
                                let completedSubSkills = skill.subSkills?.filter(item => completeData.includes(item._id)) || []
                                if (completedSubSkills.length == skill.subSkills.length) {
                                    completeData.push(skill._id)
                                }

                                let completedSkills = lesson.skills?.filter(item => completeData.includes(item._id)) || []
                                if (lesson.skills?.length == completedSkills.length) {
                                    completeData.push(lesson._id)

                                    let completedLessons = module.lessons?.filter(item => completeData.includes(item._id)) || []
                                    if (module.lessons?.length == completedLessons.length) {
                                        completeData.push(module._id)
                                    }
                                }
                            }
                        })
                    })
                })
            })
            state.userCompleted = completeData
        },
        addNote: (state, action) => {
            let notes = state.notes || []
            notes.push(action.payload)
            state.notes = notes
        },
        updateNote: (state, action) => {
            let notes = []
            state.notes.map(note => {
                if (note._id === action.payload._id) {
                    notes.push(action.payload)
                } else {
                    notes.push(note)
                }
            })
            state.notes = notes
        },
        deleteNote: (state, action) => {
            let notes = state.notes || []
            notes = notes.filter(note => note._id !== action.payload)
            state.notes = notes
        }
    },

})

export const {
    setSubSkillDescription,
    setSkill,
    setSubSkillName,
    emptyPoints,
    setSkillName,
    setData,
    setSkillSections,
    setIDs,
    setSession,
    setQuizPoints,
    setLoading,
    setModuleName,
    setLessonName,
    setChatbot,
    markCompleted,
    addNote,
    updateNote,
    deleteNote
} = userSlice.actions

export default userSlice.reducer