import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function SkillsBar(props) {
    const { t } = useTranslation()
    const skillSections = useSelector(state => state.user?.skillSections)
    const quizSkillSections = useSelector(state => state.user?.quiz)
    const moduleID = useSelector(state => state.user?.moduleID)
    const lessonID = useSelector(state => state.user?.lessonID)
    const skillID = useSelector(state => state.user?.skillID)
    const introSkillSections = skillSections?.filter(skill => { return (skill?.name === "Intro") })
    const assessSkillSections = skillSections?.filter(skill => { return (skill?.name === "Assess") })
    const simsSkillSections = skillSections?.filter(skill => { return (skill?.name === "SIMS") })

    const [activeIntro, setActiveIntro] = useState(false)
    const [activeAssess, setActiveAssess] = useState(false)
    const [activeSims, setActiveSims] = useState(false)
    const [activeQuiz, setActiveQuiz] = useState(false)

    const introActive = () => {
        setActiveIntro(true)
        setActiveAssess(false)
        setActiveSims(false)
        setActiveQuiz(false)
    }
    const assessActive = () => {
        setActiveIntro(false)
        setActiveAssess(true)
        setActiveSims(false)
        setActiveQuiz(false)

    }

    const simsActive = () => {
        setActiveIntro(false)
        setActiveAssess(false)
        setActiveSims(true)
        setActiveQuiz(false)

    }

    const quizActive = () => {
        setActiveIntro(false)
        setActiveAssess(false)
        setActiveSims(false)
        setActiveQuiz(true)

    }
    return (
        <>
            <Link
                to={`/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`}
                className="mybtn customizedbtn go-back-mobile"
                style={{ background: "transparent" }}
            >
                <ArrowBackIcon color="inherit" sx={{ mr: 1 }} />
                {t("global.back")}
            </Link>
            {/* <Link to={`/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`} className="go-back-mobile">
                <ArrowBackIcon color="inherit" sx={{ mr: 1 }} /> {t("back")}
            </Link> */}
            <div className="skills-sidebar-container">
                <div className="sidebar">
                    <Link to={`/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`} className="go-back">
                        <KeyboardBackspaceRoundedIcon style={{ fontSize: '25px' }} />
                    </Link>
                    {
                        skillSections.length !== 0 ?
                            <>
                                {
                                    introSkillSections.length !== 0 &&
                                    <NavLink
                                        to={"/skill-detail"}
                                        className={({ isActive }) => isActive ? "bar-item active mt-0 " + introActive() : "bar-item "} >
                                        {
                                            activeIntro ?
                                                <img src="../assets/img/intro1.svg" alt="" />
                                                :
                                                <img src="../assets/img/intro.svg" alt="" />
                                        }

                                        <p>{t("global.intro")}</p>
                                    </NavLink>
                                }
                                {
                                    assessSkillSections.length !== 0 &&
                                    <NavLink
                                        to={"/skill-detail-assess"}
                                        className={({ isActive }) => isActive ? "bar-item active " + assessActive() : "bar-item "}
                                    >
                                        {
                                            activeAssess ?
                                                <img src="../assets/img/assets1.svg" alt="" />
                                                :
                                                <img src="../assets/img/assets.svg" alt="" />
                                        }
                                        <p>{t("global.assess")}</p>
                                    </NavLink>
                                }
                                {
                                    simsSkillSections.length !== 0 &&
                                    <NavLink
                                        to={"/skill-detail-sims"}
                                        className={({ isActive }) => isActive ? "bar-item active " + simsActive() : "bar-item "}>
                                        {
                                            activeSims ?
                                                <img src="../assets/img/sim1.svg" alt="" />
                                                :
                                                <img src="../assets/img/sim.svg" alt="" />
                                        }
                                        <p>{t("global.sims")}</p>
                                    </NavLink>
                                }
                                {
                                    quizSkillSections?.length !== 0 &&
                                    <NavLink
                                        to={"/skill-detail-quiz"}
                                        className={({ isActive }) => isActive ? "bar-item active " + quizActive() : "bar-item "}>
                                        {
                                            activeQuiz ?
                                                <img src="../assets/img/quiz1.svg" alt="" />
                                                :
                                                <img src="../assets/img/quiz.svg" alt="" />
                                        }
                                        <p>{t("global.quiz")}</p>
                                    </NavLink>
                                }
                            </> : <p className="text-light">{t("global.noResultFound")}</p>
                    }
                </div>
            </div >
        </>
    )
}