

import { useTranslation } from 'react-i18next'
export default function TurnPhoneV() {

    const { t } = useTranslation();
    return (
        <>
            <div className="body-bg">
                <div className="container turn-screen-container">
                    <div className="container-inner">
                        <img width="130px" className="img-logo" src="/assets/svg/brookline-white-logo.png" alt="" />
                        <img className="turn-img" src="/assets/img/turn.png" alt="" />
                        <h1>{t("turn.gamemode")}</h1>
                        <br />

                    </div>
                </div>
            </div>
        </>
    )
}
