import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
    useEffect(() => {
        localStorage.setItem("fetch-data", false)
    }, [])
    return (
        <>
            <div className="container mt-5">
                <div className="row p-3 justify-content-center align-items-center">
                    <div className="col-sm-6">
                        <img width="100%" src="../assets/img/error-img.jpg" alt="" />
                    </div>
                    <div className="col-sm-6">
                        <h4 className="text-danger mt-5">No Internet Connection!</h4>
                        <p>Chek Your Internet Connection and try again!</p>
                        <Link to="/get-started" className="btn btn-lg btn-block bg-primary1">Try Again</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
